<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <h1>Radnici</h1>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <router-link to="/employees/create">
                    <b-btn variant="primary">Novi radnik</b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="employees">
            <b-col>
                <osta-table
                        position
                        :search-keys="searchKeys"
                        :items="employees"
                        :fields="employeesFields"
                        @rowClicked="goToEmployee">
                </osta-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "employees",
        computed: {
            selectedClient: function() {
                return this.$store.getters.selectedClient;
            }
        },
        data() {
            return {
                employees: null,
                employeesFields: [
                    {
                        key: "first_name",
                        label: "Ime",
                        sortable: true
                    },
                    {
                        key: "last_name",
                        label: "Prezime",
                        sortable: true
                    },
                    {
                        key: "jmbg",
                        label: "JMBG",
                        sortable: false
                    }
                ],
                searchKeys: [
                    'first_name',
                    'last_name'
                ]
            };
        },
        methods: {
            goToEmployee(id) {
                this.$router.push("/employees/" + id);
            }
        },
        created() {
            axiOsta.post(api.clients + 'employees', {
                client_id: this.$parent.selectedClient.id,
            }).then(response => {
                if (response.status === 200) {
                    this.employees = response.data;
                }
            });
        }
    };
</script>
